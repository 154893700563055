
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars';

@import '../../style/buttons';
@import '../../style/icons.scss';
@import '../../style/font-family';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.bt-section-featured {
  margin: 30px 0 0 0;

  .featured-section-title {
    font-size: 18px;
    color: $secondary-text;
    @include roboto-medium;
    margin-bottom: 10px;
  }

  .latest-webcast-title {
    font-size: 26px;
    color: $primary-text;
    margin-bottom: 10px;
    @include roboto-medium;
    word-wrap: break-word;

    a {
      color: $primary-text;
      @include roboto-medium;
    }
  }

  span {
    color: $neutral-text;
  }

  p {
    font-size: 16px;
    margin-bottom: 30px;
    color: $secondary-text;
    &.success-message {
      display: inline-block;
      margin-right: 30px;
    }
    @include roboto-light;
  }

  .item-meta {
    margin-bottom: 10px;
    position: relative;

    svg {
      margin-right: 6px;
      color: $bt-light-grey;
    }
  }
}

.section-featured-img-holder {
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid $background-default;
  border-radius: 10px;
  box-shadow: 0px 0px 3px $box-shadow-color;
  width: 100%;

  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.bt-featured-section-btn {
  &:hover {
    background-color: $button-primary-hover-color;
  }
}

.cal-icon {
  margin-right: 6px;
}
.arrow-icon {
  margin-left: 6px;
}
.success-icon {
  display: inline-block;
  margin-left: 30px;
}

@include media-breakpoint-down(md) {
  .section-featured-img-holder {
    a {
      img {
        height: auto;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .bt-section-featured {
    .featured-left {
      flex: 0 0 455px;
      width: 0;
    }

    .featured-right {
      flex: 1 1 auto;
      width: 0 !important;
    }
  }

  .series-section-featured {
    .featured-left {
      flex: 0 0 455px;
    }
  }
}


@include media-breakpoint-up(xl) {
  .col-md-4 {
    width: 45% !important;
  }
}

@include media-breakpoint-down(lg) {
  .bt-section-featured {
    p.description {
      display: none;
    }
    .item-meta {
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .section-featured-img-holder {
    margin-bottom: 10px;
  }
  .featured-right {
    .bt-elastic {
      width: 100%;
    }
  }
}

.bt-section-featured-container {
  display: block;
}

.bt-section-featured-body {
  position: relative;
  display: block;
  flex: 1 1 80%;
}

.company-logo-container {
  padding: 0 0px 0 10px;
  border-left: 1px solid $border-divider-color;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 20%;

  .series-feat-presented-by {
    font-size: 12px;
    color: $secondary-text;
    @include roboto-medium;
    margin-bottom: 15px;
  }

  .company-logo {
    img {
      width: 100% !important;
      min-width: 100% !important;
      height: auto !important;
      min-height: auto !important;
      margin: 0 !important;
    }
  }
}

.company-logo-holder {
  position: relative;

  .company-logo {
    height: 40px;
    width: 100%;
    position: relative;
    display: block;
    object-fit: cover;
  }
}

@include media-breakpoint-down(lg) {
  .company-logo-container {
    display: none;
  }

  .bt-section-featured-body {
    width: 100%;
  }
}

.channel-featured-talk {
  .featured-left {
    .section-featured-img-holder {
      img {
        object-fit: unset;
      }
    }
  }

  .featured-right {
    > .row-right {
      height: 100%;

      .bt-section-featured-body {
        display: flex;
        flex-direction: column;
      }

      .bt-elastic {
        position: relative;
        align-self: flex-start;
        margin-top: auto;
        top: -3px;
      }
    }
  }
}