
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../style/skeletonGrids.scss';
@import '@bt-branding/colors';
@import '../../style/globalColorVars.scss';

.section-featured-img-holder {
	min-height: 235px;
	background-color: $bt-tertiary;
	border-radius: 10px;
}

.bt-section-featured {
	margin: 30px 0 30px 0;

	h4 {
		background-color: $bt-tertiary;
		width: 20%;
		height: 21px;
		margin-bottom: 10px;
	}

	h2 {
		background-color: $bt-tertiary;
		width: 100%;
		height: 30px;
	}

	p {
		background-color: $bt-tertiary;
		width: 100%;
		height: 41px;
		margin-bottom: 20px;
	}

	a {
		width: 120px;
		height: 40px;
		background-color: $bt-tertiary;
		position: relative;
		display: block;
	}
}
